import React, { useState } from "react";
import ContentBox from "./ContentBox";
import Trade from "./Trade";

function HeroTab() {
  const [isOpen, setIsOpen] = useState(false);
  console.log("isOpen", isOpen);
  return (
    <div>
      <div className={`${isOpen ? "show menu-toggle" : ""} `}>
        <div className="content-body">
          <div className="container-fluid">
            {/* Row */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body pt-3 pb-3">
                    <div className="coin-warpper d-flex align-items-center justify-content-between flex-wrap">
                      <div>
                        <ul className="nav nav-pills" role="tablist">
                          <li
                            className=" nav-item wow fadeInUp"
                            data-wow-delay="0.2s"
                          >
                            <button
                              className="nav-link active bitcoin ms-0 d-flex align-items-center gap-1"
                              id="nav-bitcoin-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-bitcoin"
                              type="button"
                              role="tab"
                              aria-selected="true"
                            >
                              <img
                                src="assets/images/img/arbitrum.svg"
                                width={19}
                                alt=""
                              />
                              <h6 className="mb-0"> Arbitrum</h6>
                            </button>
                          </li>
                          <li
                            className="nav-item wow fadeInUp"
                            data-wow-delay="0.3s"
                          >
                            <button
                              className="nav-link etherum d-flex align-items-center gap-1"
                              id="nav-etherum-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-etherum"
                              type="button"
                              role="tab"
                              aria-selected="false"
                            >
                              <img
                                src="assets/images/img/optimism.svg"
                                width={19}
                                alt=""
                              />
                              <h6 className="mb-0"> Optimisim </h6>
                            </button>
                          </li>
                          <li
                            className="nav-item wow fadeInUp"
                            data-wow-delay="0.4s"
                          >
                            <button
                              className="nav-link etherum d-flex align-items-center gap-1"
                              id="nav-dash-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-dash"
                              type="button"
                              role="tab"
                              aria-selected="false"
                            >
                              <img
                                src="assets/images/img/polygon.svg"
                                width={19}
                                alt=""
                              />
                              <h6 className="mb-0"> Polygon </h6>
                            </button>
                          </li>
                          <li
                            className="nav-item wow fadeInUp"
                            data-wow-delay="0.4s"
                          >
                            <button
                              className="nav-link etherum d-flex align-items-center gap-1"
                              id="nav-litcoin-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-litcoin"
                              type="button"
                              role="tab"
                              aria-selected="false"
                            >
                              <img
                                src="assets/images/img/ton.svg"
                                width={19}
                                alt=""
                              />
                              <h6 className="mb-0"> Ton </h6>
                            </button>
                          </li>
                          <li
                            className="nav-item wow fadeInUp"
                            data-wow-delay="0.5s"
                          >
                            <button
                              className="nav-link etherum d-flex align-items-center gap-1"
                              id="nav-ripple-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-ripple"
                              type="button"
                              role="tab"
                              aria-selected="false"
                            >
                              <img
                                src="assets/images/img/sui.svg"
                                width={15}
                                alt=""
                              />
                              <h6 className="mb-0"> Sui</h6>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="tab-content" id="nav-tabContent">
                  <Trade />
                  <div
                    className="tab-pane fade show "
                    id="nav-etherum"
                    role="tabpanel"
                    aria-labelledby="nav-etherum-tab"
                  >
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body pb-2">
                          <h1 className="text-center no-border font-w600 fs-60 mt-2">
                            <span className="text-warning">Maximize </span>
                            Profits with{" "}
                            <span className="text-warning">Automated </span>
                            Crypto Trading Bots
                            <span className="text-danger"></span>
                            <br /> BotVerse Zone with{" "}
                            <span className="text-warning">
                              no additional charges{" "}
                            </span>
                          </h1>
                          <h4 className="text-center ">
                            Trusted by Millions, Facilitating Over multiple
                            Crypto Transactions.
                          </h4>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="text-center mt-4 mb-4 d-flex justify-content-center gap-3">
                                <a
                                  href="#"
                                  className="btn btn-outline-warning "
                                >
                                  Coming Soon
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-outline-warning   "
                                >
                                  Coming Soon
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show "
                    id="nav-dash"
                    role="tabpanel"
                    aria-labelledby="nav-dash-tab"
                  >
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body pb-2">
                          <h1 className="text-center no-border font-w600 fs-60 mt-2">
                            <span className="text-warning">Maximize </span>
                            Profits with{" "}
                            <span className="text-warning">Automated </span>
                            Crypto Trading Bots
                            <span className="text-danger"></span>
                            <br /> BotVerse Zone with{" "}
                            <span className="text-warning">
                              no additional charges{" "}
                            </span>
                          </h1>
                          <h4 className="text-center ">
                            Trusted by Millions, Facilitating Over multiple
                            Crypto Transactions.
                          </h4>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="text-center mt-4 mb-4 d-flex justify-content-center gap-3">
                                <a
                                  href="#"
                                  className="btn btn-outline-warning "
                                >
                                  Coming Soon
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-outline-warning   "
                                >
                                  Coming Soon
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show "
                    id="nav-litcoin"
                    role="tabpanel"
                    aria-labelledby="nav-litcoin-tab"
                  >
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body pb-2">
                          <h1 className="text-center no-border font-w600 fs-60 mt-2">
                            <span className="text-warning">Maximize </span>
                            Profits with{" "}
                            <span className="text-warning">Automated </span>
                            Crypto Trading Bots
                            <span className="text-danger"></span>
                            <br /> BotVerse Zone with{" "}
                            <span className="text-warning">
                              no additional charges{" "}
                            </span>
                          </h1>
                          <h4 className="text-center ">
                            Trusted by Millions, Facilitating Over multiple
                            Crypto Transactions.
                          </h4>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="text-center mt-4 mb-4 d-flex justify-content-center gap-3">
                                <a
                                  href="#"
                                  className="btn btn-outline-warning "
                                >
                                  Coming Soon
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-outline-warning   "
                                >
                                  Coming Soon
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show "
                    id="nav-ripple"
                    role="tabpanel"
                    aria-labelledby="nav-ripple-tab"
                  >
                    <div className="col-xl-12">
                      <div className="card">
                        <div className="card-body pb-2">
                          <h1 className="text-center no-border font-w600 fs-60 mt-2">
                            <span className="text-warning">Buy</span> and{" "}
                            <span className="text-danger">Sell</span> Coins at
                            the
                            <br /> CryptoZone with no additional charges
                          </h1>
                          <h4 className="text-center ">
                            Trusted by millions user with over $1 Trillion in
                            crypto transactions.
                          </h4>
                          <div className="row">
                            <div className="col-xl-12">
                              <div className="text-center mt-4 mb-4 d-flex justify-content-center gap-3">
                                <a
                                  href="#"
                                  className="btn btn-outline-warning "
                                >
                                  Coming Soon
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-outline-warning  "
                                >
                                  Coming Soon
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroTab;
