import React, { useState } from "react";
import Header from "../Coman/Header";
import HeroTab from "./HeroTab";

function Index() {
    const [isOpen, setIsOpen] = useState(false);

    

    // Toggle function
    
  return (
    <div  className={`${isOpen?"show menu-toggle":""} `}>
      <Header isOpen={isOpen} setIsOpen={setIsOpen}/>
      <HeroTab />
      
    </div>
  );
}

export default Index;
