import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContextProvider";

export default function Header({ isOpen, setIsOpen }) {
  const { account, open, formatAddress, } = useContext(AppContext);

  const [isOpenTab, setIsOpenTab] = useState(false);
  const currentPath = window.location.pathname;

  return (
    <div>
      <div className="nav-header">
        <a className="brand-logo">
          <img
            src="assets/images/img/logo_light.png"
            className={`${isOpen ? "d-none" : "img-fluid d-none d-lg-block"} `}
            alt=""
          />
          <img
            src="assets/images/img/botverse_512.png"
            width={50}
            className={`${isOpen ? "img-fluid  d-none d-lg-block" : "d-none "} `}
            alt=""
          />
          <img
            src="assets/images/img/botverse_512.png"
            width={50}
            className="d-block d-lg-none"
            alt=""
          />

        </a>
        <div className="nav-control" onClick={() => setIsOpen(!isOpen)}>
          <div className="hamburger" >
            <span className="line" />
            <span className="line" />
            <span className="line" />
            <svg
              width={26}
              height={26}
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x={22} y={11} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={11} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={22} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={11} y={11} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={11} y={22} width={4} height={4} rx={2} fill="#2A353A" />
              <rect width={4} height={4} rx={2} fill="#2A353A" />
              <rect y={11} width={4} height={4} rx={2} fill="#2A353A" />
              <rect x={22} y={22} width={4} height={4} rx={2} fill="#2A353A" />
              <rect y={22} width={4} height={4} rx={2} fill="#2A353A" />
            </svg>
          </div>
        </div>
      </div>

      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
              </div>
              <div className="navbar-nav header-right">
                <div className="nav-item d-flex align-items-center">
                  {!account ?
                    <a href="#" className="btn btn-warning" onClick={() => open()}>
                      Connect Wallet
                    </a> :
                    <a href="#" className="btn btn-warning  ">
                      {formatAddress(account)}
                    </a>}
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            <li>
              <Link
                to="/"
                className={`${currentPath === "/" ? "he-active d-flex align-items-center gap-1" : " text-white d-flex align-items-center gap-1"
                  }`}

              >
                {/* <i className="fa-regular fa-gear fw-bold" /> */}
                <img src="assets/images/img/arbitrum.svg" width={18} alt="" />
                <span className="nav-text mb-0">Liquidity Deployed</span>
                {/* <span className="badge badge-xs style-1 badge-danger">New</span> */}
              </Link>

            </li>
            <li>
              <a
                className="has-arrow align-items-center gap-1 d-flex" onClick={() => setIsOpenTab(!isOpenTab)}

              >
                <img src="assets/images/img/arbitrum.svg" width={18} alt="" />
                <span className="nav-text text-white ">Liquidity Deployed</span>
              </a>
              <ul className={`${isOpenTab ? "d-block" : "d-none"} `}>
                <li>
                  <Link to="#">Aave</Link>
                </li>
                <li>
                  <Link to="/aave" className={`${currentPath === "/aave" ? "tab-acti" : " "
                    }`}>Add Content</Link>
                </li>
                <li>
                  <Link to="#">Menu</Link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
