import React, { createContext, useEffect, useState } from "react";
import {
  createWeb3Modal,
  defaultConfig,
  useDisconnect,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";
import axios from "axios";
// import PolygonTokens from "./constants/PolygonTokens.json"
const projectId = process.env.REACT_APP_PROJECT_ID;

const metadata = {
  name: "Botverse",
  description: "Botverse boat",
  url: "http://localhost:3000", // origin must match your domain & subdomain
  icons: ["http://localhost:3000/favicon.png"],
};
const chains = [
  {
    chainId: 42161,
    name: "Arbitrum One Mainnet",
    currency: "ETH",
    explorerUrl: "https://arbiscan.io/",
    rpcUrl: "https://arb1.arbitrum.io/rpc"  
   }
];
const ethersConfig = defaultConfig({
  metadata,
  defaultChainId: 42161,
});

createWeb3Modal({
  ethersConfig,
  chains: chains,
  projectId,
  enableAnalytics: true,
});

export const AppContext = createContext(null);

const AppContextProvider = ({ children }) => {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [account, setaccount] = useState(address);

  const { open, close } = useWeb3Modal();
  const { walletProvider } = useWeb3ModalProvider();
  const { disconnect } = useDisconnect();
  const [settings, setsettings] = useState([]);
  const [provider, setProvider] = useState(null);
  const [user_stats, setuser_stats] = useState(null);
  const [stats, setstats] = useState(null);
  const [signer, setSigner] = useState(null);
  const [balance, setBalance] = useState(0);
  const [dbuser, setdbuser] = useState(null);

  const getAndSetProvider = async () => {
    try {
      if (walletProvider) {
        const etherProvider = new ethers.providers.Web3Provider(walletProvider);
        const etherSigner = await etherProvider.getSigner();
        console.log("eth", etherSigner);

        setProvider(etherProvider);
        setSigner(etherSigner);
      } else {
        setProvider(null);
        setSigner(null);
      }
    } catch (err) { }
  };
  const getudata = async (address) => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_LINK}`, {
      method: "user",
      submethod: "getbyid",
      key: process.env.REACT_APP_KEY,
      address: address ? address.toLowerCase() : address,
    });
  };

  const getSetting = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "setting",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setsettings(res.data.data);
      });
  };
  function addTimeToDate(date, hours, minutes) {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    newDate.setMinutes(newDate.getMinutes() + minutes);
    return newDate;
  }
  function countDate(totalHours) {
    let hours = Math.floor(totalHours);
    let minutes = Math.round((totalHours - hours) * 60);

    return { hours, minutes }
    // console.log(`Hours: ${hours}, Minutes: ${minutes}`); // Outputs "Hours: 2, Minutes: 24"
  }
  const getsmcs = async () => {
    if (!isConnected || !settings.token || signer === null) return;
    let contract = new ethers.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      signer
    );
    let maincontractwrite = new ethers.Contract(
      settings.trade_contract,
      JSON.parse(settings.trade_contract_abi),
      signer
    );
    // setstakcontract(maincontractwrite)

    if (account) {
      var usrs = await maincontractwrite.users(account);
      var isFeeClaimed = await maincontractwrite.isFeeClaimed(account);

      var pkg = await maincontractwrite.packages(usrs.lastPackageId);
      // console.log("users",pkg );
      var statistics = await maincontractwrite.stats(account);
      var stats = {
        claimedTradesPerDay: statistics.claimedTradesPerDay,
        lastClaimed: Number(statistics.lastClaimed),
        totalProfit: Number(ethers.utils.formatUnits(statistics.totalProfit, 6)).toFixed(5),
        totalTrades: Number(statistics.totalTrades),
        isFeeClaimed: isFeeClaimed
      }
      // console.log("stats", stats);
      var cdate = countDate(24 / pkg.tradesPerDay);
      console.log(cdate);
      var addtimde = addTimeToDate(stats.lastClaimed * 1000, cdate.hours, cdate.minutes);
      stats.nextcall = addtimde
      // console.log("addtimde", stats,settings);
      setuser_stats(stats)
      var balance = await contract.balanceOf(account);
      setBalance(Number(ethers.utils.formatUnits(balance, 6)).toFixed(2));
    }

    var t_profit = await maincontractwrite.totalProfit();
    var t_trades = await maincontractwrite.totalTrades();
    var t_trade_amt = await maincontractwrite.totalTradeAmount();
    setstats({
      totalProfit: Number(ethers.utils.formatUnits(t_profit, 6)).toFixed(5),
      totalTrades: Number(t_trades),
      totalTradeAmt: Number(ethers.utils.formatUnits(t_trade_amt, 6)).toFixed(5),
    })
    // var tknrate = await maincontractwrite.tokenrate();
    // settokenrate(Number(ethers.utils.formatUnits(tknrate, 18)).toFixed(2));

  };
  const getuserDB = async () => {
    if (!account) {
      return
    }

    var data = await getudata(account);
    if (data.data.error) {
      setdbuser(null);

      return "";
    }
    setdbuser(data.data.data);

  };
  // function getSymbolByAddress(address) {
  //   const token = PolygonTokens.find(token => token.address.toLowerCase() === address.toLowerCase());
  //   return token ? token.symbol : null; // Return null if address not found
  // }

  useEffect(() => {
    getuserDB();
    // }, [account]);
  }, [account]);

  useEffect(() => {
    getsmcs();
    // }, [account, address,library, settings]);
  }, [isConnected, account, walletProvider, settings, signer]);
  useEffect(() => {
    getAndSetProvider();
  }, [walletProvider, account]);
  useEffect(() => {
    getSetting();
  }, []);
  useEffect(() => {
    setaccount(address);
  }, [address]);
  const formatAddress = (address) => {
    if (address) {
      return address.substr(0, 6) + "..." + address.substr(-6, 6);
    }
  };

  return (
    <AppContext.Provider value={{ account, isConnected, walletProvider, stats, user_stats, open, settings, close, provider, signer, disconnect, getsmcs, dbuser,formatAddress }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
