import React, { useContext, useState } from "react";
import ContentBox from "./ContentBox";
import { AppContext } from "../../AppContextProvider";
import axios from "axios";
import { ethers } from "ethers";

function Trade() {
  const { account, settings, signer, open, isConnected, dbuser, getsmcs, user_stats, stats } = useContext(AppContext);
  const [trdone, settrdone] = useState(false);


  const TradeNow = async () => {
    if (!account) {
      console.error("Connect first")
      return
    }
    settrdone(true);

    // var data = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
    //   method: "trade",
    //   submethod: "get",
    //   key: process.env.REACT_APP_KEY,
    // });
    // var resdata = data.data
    // console.log("ew", resdata);

    if (!isConnected || !settings.trade_contract || signer === null) return;
    let trade_contract = new ethers.Contract(
      settings.trade_contract,
      JSON.parse(settings.trade_contract_abi),
      signer
    );
    let token_contract = new ethers.Contract(
      settings.arb_token,
      JSON.parse(settings.arb_token_abi),
      signer
    );
    const allowance = await token_contract.allowance(
      account,
      settings.trade_contract
    );
    // console.log("allowance",Number(ethers.utils.formatEther(allowance)) );
    // return
    async function main() {
      try {
        var resdata = {
          "trade": {
            "trade":
              [
                [
                  "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
                  "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
                  "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
                ],
                [
                  "0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8",
                  "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
                  "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506"
                ]
              ],
            "amountIn": 0.0038,
            "amountOut": "0.00376005304128025"
          }
        }

        var stk = null
        stk = await trade_contract.initateArbitrage(
          "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
          "1000000",
          "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
          resdata.trade.trade
        );
        // const randomHash = Array.from(crypto.getRandomValues(new Uint8Array(16))).map(byte => byte.toString(16).padStart(2, '0')).join('');

        const receipt = await stk.wait();
        // sethash(receipt.transactionHash);
        // sethash(randomHash);
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "trade",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            trade: resdata,
            hash: receipt.transactionHash
          })
          .then((res) => {
            console.log("res", res.data);
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              return "";
            }
          });
        settrdone(false);
        alert("Transaction done");

        getsmcs()
      } catch (error) {
        console.log("Error", error);
        settrdone(false);
        alert("Transaction declined");

        return;
      }
    }
    if (user_stats.isFeeClaimed === false) {
      // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
      // if (false) {
      try {
        var approv = await token_contract.approve(
          settings.trade_contract,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        alert("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        alert("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }

  }

  return (
    <div
      className="tab-pane fade show active"
      id="nav-bitcoin"
      role="tabpanel"
      aria-labelledby="nav-bitcoin-tab"
    >
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body pb-2">
            <h1 className="text-center no-border font-w600 fs-60 mt-2">
              <span className="text-warning">Maximize </span>
              Profits with{" "}
              <span className="text-warning">Automated </span>
              Crypto Trading Bots
              <span className="text-danger"></span>
              <br /> BotVerse Zone with{" "}
              <span className="text-warning">
                no additional charges{" "}
              </span>
            </h1>
            <h4 className="text-center ">
              Trusted by Millions, Facilitating Over multiple
              Crypto Transactions.
            </h4>
            <div className="row">
              <div className="col-xl-12">
                <div className="text-center mt-4 mb-4 d-flex justify-content-center gap-3">
                  <button
                    href="#"
                    className="btn btn-outline-warning "
                    onClick={() => window.location.reload()}
                  >
                    Refresh
                  </button>
                  {trdone ?
                    <img src="assets/images/loaderg.gif" alt="" srcset="" style={{ width: "70px" }} /> :
                    <button
                      href="#"
                      className="btn btn-outline-warning"
                      onClick={() => TradeNow()}
                    >
                      Trade Now
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ContentBox /> */}
    </div>

  );
}

export default Trade;
