import React, { useState } from 'react'
import Header from '../Coman/Header'

function Aave() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
          <div  className={`${isOpen?"show menu-toggle":""} `}>
          <Header isOpen={isOpen} setIsOpen={setIsOpen}/>
    </div>
    </div>
  )
}

export default Aave